@mixin mobile {
	@media screen and (max-width: 768px) and (orientation: portrait) { @content; }
}





@function vw($px) {
	@return (($px/1050) * 100)+vh;
}
@function vw2($px) {
	@return (($px/1920) * 100)+vw;
}

@function vw3($px) {
	@return (($px/375) * 100)+vw;
}