/* ------------------------------------------------------------------------------
  1.  Global
      1.1 Reset styles
      1.2 Fonts
      1.3 General
  2.  Header
  3.  Main
      3.1 Home page
      3.2 Blog page

-------------------------------------------------------------------------------*/

@import "partials/mixins";

/*-------------------------------------------------------------------------------
  1. Global
-------------------------------------------------------------------------------*/

/* 1.1 Reset styles */
@import "libs/reset";

/* 1.2 Fonts */
@import "libs/fonts";

/* 1.3 General */
@import "partials/main";

/*-------------------------------------------------------------------------------
  2. Header
-------------------------------------------------------------------------------*/
@import "partials/header";

/*-------------------------------------------------------------------------------
  3. Main
-------------------------------------------------------------------------------*/
/* 3.1 Home page */
@import "partials/content";

