$main-color: #E2DCC8;
$orange-color: #d22222;
$font: 'HelveticaNeueCyr', sans-serif;

html,
body {
	max-width: 100%;
	height: 100%;
}

html {
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-o-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	text-size-adjust: 100%;
	font-size: 16px;
}
body{
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	-webkit-locale: auto;
	font-family: $font;
	color: $main-color;
	background: #1C1C1C;
	background: linear-gradient(to right, rgba(36,31,31,1) 0%, rgba(36,31,31,1) 32%, rgba(74,71,70,1) 100%);
}
input, button {
	-webkit-appearance: none;
}

a{
	text-decoration: none;
	color: $main-color;
	&:hover{
		color: $orange-color;
	}
}

ol,
ul,
nav {
	list-style: none;
}

img {
	display: block;
}

*:focus {
	outline: none;
}

*, :after, :before {
	box-sizing: border-box;
	margin: 0;
}



body::-webkit-scrollbar {
	width: 5px;
	background: $main-color;
}

body::-webkit-scrollbar-thumb {
	background: $orange-color;
}

.main{
	width: 100%;
	min-height: 100%;
	box-sizing: border-box;
	position: relative;
	&--home{
		@include mobile{
			opacity: 1 !important;
		}
	}
}

.noise {
	z-index: -1;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	pointer-events: none;
	opacity: .15;
	&--inner{
		width: 100%;
		height: 100%;
		position: absolute;
	}
}


.btn{
	border: none;
	color:$main-color;
	cursor: pointer;
	display: inline-block;
	border-radius: 70px;
	background: $orange-color;
	transition: background 0.3s;
	font-size: vw2(18);
	line-height: 130%;
	text-transform: uppercase;
	text-align: center;
	padding: vw2(14) vw2(60);
	@include mobile{
		border-radius: vw3(50);
		font-size: vw3(18);
		padding: vw3(14) vw3(60);
	}
	&:hover{
		background: $main-color;
		color: $orange-color;
	}
	svg{
		max-width: vw2(36);
		max-height: vw2(16);
		display: block;
		object-fit: contain;
		object-position: center;
		@include mobile{
			max-width: vw3(36);
			max-height: vw3(16);
		}
	}
	&--arrow{
		padding: vw2(26) vw2(36);
		@include mobile{
			padding: vw3(26) vw3(36);
		}
	}
	&--invert{
		background: transparent;
		border: 1px solid $main-color;
	}
}

.h1{
	font-weight: 500;
	font-size: vw2(76);
	line-height: 0.91;
	text-transform: uppercase;
	@include mobile{
		font-size: vw3(44);
	}
}

.small{
	font-size: vw2(15);
	line-height: 130%;
	@include mobile{
		font-size: vw3(15);
	}
}

.input-file{
	input{
		display: none;
	}
	span{
		cursor: pointer;
		text-decoration: underline;
	}
}