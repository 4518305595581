.scroll {
  z-index: 1;
  position: fixed;
  width: 100%;
  @include mobile{
    position: static;
    padding-top: vw3(70);
  }
}

.page-title,
.sticky-title {
  overflow: hidden;
  width: vw2(82);
  height: 100%;
  position: fixed;
  flex-shrink: 0;
  z-index: 20;
  transform: translateX(-100%);
  border-right: 1px solid rgba(230, 227, 217, 0.3);
  box-sizing: border-box;
  @include mobile{
    width: 100%;
    height: vw3(50);
    position: sticky;
    top: vw3(70);
    transform: none;
    border-right: 0;
    border-bottom: 1px solid rgba(230, 227, 217, 0.3);
  }
  &.show{
    transform: translateX(0);
  }
  .number, .title{
    display: block;
    transform: translateZ(0);
  }
  ul{
    text-transform: uppercase;
    width: 100vh;
    font-size: vw2(38);
    position: absolute;
    bottom: 0;
    left: 0;
    height: vw2(82);
    transform-origin: 0 100%;
    transform: rotate(-90deg) translateY(100%) translateX(vw2(89));
    white-space: nowrap;
    z-index: 1;
    @include mobile{
      width: 100%;
      font-size: vw3(20);
      position: static;
      height: 100%;
      transform: none;
    }
    li{
      position: absolute;
      padding: 0 vw2(128) 0 vw2(35);
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      left: 0;
      width: 100%;
      top: 0;
      height: 100%;
      align-items: center;
      @include mobile{
        padding: 0 vw3(20);
      }
    }
  }
}
.page-title{
  background: #1C1C1C;
  background: rgba(36,31,31,1);
  @include mobile{
    display: none;
  }
  ul{
    li:not(:first-child){
      transform: translateY(-100%);
    }
  }
}
.sticky-title {
  transform: translateX(0) !important;
  letter-spacing: 0.02em;
  border-left: 1px solid rgba(230, 227, 217, 0.3);
  @include mobile{
    border-left: 0;
    border-top: 1px solid rgba(230, 227, 217, 0.3);
    background: #1C1C1C;
    background: rgba(36,31,31,1);
  }
  ul {
    transform: rotate(-90deg) translateY(100%) translateX(0) !important;
    @include mobile{
      transform: none !important;
    }
  }

  span {
    display: inline-block;
  }
}

.home-page {
  height: 100vh;
  top: 0;
  left: 0;
  padding-top: vw2(89);
  display: flex;
  @include mobile{
    height: auto;
    position: static;
    padding-top: 0;
    display: block;
  }
  .scrollable {
    display: flex;
    height: 100%;
    flex-shrink: 0;
    @include mobile{
      display: block;
      height: auto;
    }
    >* {
      flex-shrink: 0;
    }
  }
  section{
    display: flex;
    position: relative;
    height: 100%;
    @include mobile{
      display: block;
      height: auto;
    }
  }
  .content{
    width: 100vw;
    height: 100%;
    padding-left: vw2(82);
    box-sizing: border-box;
    display: flex;
    position: relative;
    @include mobile{
      display: block;
      width: 100%;
      height: auto;
      padding-left: 0;
    }
    &__bg{
      position: absolute;
      z-index: -2;
      width: calc(100% - 4.27083vw);
      height: 100%;
      background-size: cover;
      right: 0;
      top: 0;
      pointer-events: none;
      opacity: 0.5;
      @include mobile{
        width: 100%;
      }
      &::before{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.31), rgba(0, 0, 0, 0.31));
        display: block;
      }
    }
    &__left{
      width: calc(36.927vw - 4.27083vw);
      border-right: 1px solid rgba(230, 227, 217, 0.3);
      flex-shrink: 0;
      box-sizing: border-box;
      padding: vw2(22) vw2(40) vw2(22) vw2(22);
      display: flex;
      flex-direction: column;
      overflow: hidden;
      @include mobile{
        width: 100%;
        border-right: 0;
        border-bottom: 1px solid rgba(230, 227, 217, 0.3);
        padding: vw3(20);
      }
    }
    &__right{
      width: calc(100% - (36.927vw - 4.27083vw));
      padding: vw2(22) vw2(22);
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      @include mobile{
        width: 100%;
        padding: vw3(20);
      }
    }

    &__title{
      >*{
        position: relative;
      }
    }
    &__description{
      margin-top: vw2(48);
      max-width: vw2(380);
      position: relative;
      @include mobile{
        max-width: 100%;
        margin-top: vw3(30);
      }
      +.content__btn-block{
        margin-top: vw2(105);
        @include mobile{
          margin-top: vw3(40);
        }
      }
    }
    &__btn{
      &-block{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: vw2(75);
        position: relative;
        @include mobile{
          margin-top: vw3(40);
        }
      }
      &-label{
        margin-right: vw2(27);
        @include mobile{
          margin-right: vw3(27);
        }
      }
      &-btn{
        flex-shrink: 0;
      }
    }


    &__menu{
      list-style: none;
      margin-top: vw2(131);
      margin-left: vw2(-22);
      margin-right: vw2(-40);
      @include mobile{
        margin-top: vw2(50);
        margin-left: vw3(-20);
        margin-right: vw3(-20);
      }
      li{
        font-size: vw2(22);
        line-height: 160%;
        text-transform: uppercase;
        border-top: 1px solid #E2DCC8;
        border-bottom: 1px solid #E2DCC8;
        display: flex;
        position: relative;
        opacity: 0.5;
        @include mobile{
          font-size: vw3(16);
          opacity: 1;
        }
        +li{
          margin-top: -1px;
        }
        > span{
          color: inherit;
          padding: vw2(9) vw2(52) vw2(9) vw2(22);
          display: block;
          width: 100%;
          position: relative;
          @include mobile{
            padding: vw3(9) vw3(40) vw3(9) vw3(20);
          }
        }
        > a {
          color: inherit;
          padding: vw2(9) vw2(52) vw2(9) vw2(22);
          display: block;
          width: 100%;
          position: relative;
          @include mobile{
            padding: vw3(9) vw3(32) vw3(9) vw3(20);
            &:not(.noicon)::after{
              content: "";
              position: absolute;
              right: vw2(16);
              top: 50%;
              transform: translateY(-50%);
              width: vw2(19);
              height: vw2(19);
              background: url(../img/arrow-link.svg) center center no-repeat;
              background-size: contain;
              pointer-events: none;
              user-select: none;
              @include mobile{
                right: vw3(16);
                width: vw3(16);
                height: vw3(16);
              }
            }
          }
        }
        &:hover{
          opacity: 0.8;
        }
        &.active{
          opacity: 1;
          a{
            &:not(.noicon)::after{
              content: "";
              position: absolute;
              right: vw2(16);
              top: 50%;
              transform: translateY(-50%);
              width: vw2(19);
              height: vw2(19);
              background: url(../img/arrow-link.svg) center center no-repeat;
              background-size: contain;
              pointer-events: none;
              user-select: none;
              @include mobile{
                right: vw3(16);
                width: vw3(16);
                height: vw3(16);
              }
            }
          }
        }
      }
    }
    &__subtitle{
      font-weight: 500;
      font-size: vw2(40);
      line-height: 120%;
      max-width: vw2(514);
      position: relative;
      @include mobile{
        font-size: vw3(32);
        max-width: 100%;
      }
      span{
        color: $orange-color;
      }
    }

    &__awards{
      margin-left: vw2(-22);
      margin-right: vw2(-22);
      height: -webkit-fill-available;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include mobile{
        margin-left: vw3(-20);
        margin-right: vw3(-20);
        margin-top: vw3(20);
        display: block;
        height: auto;
      }
    }
    &__award{
      min-height: vw(170);
      display: flex;
      align-items: center;
      padding: vw(22) vw2(74);
      overflow: hidden;
      position: relative;
      @include mobile{
        flex-wrap: wrap;
        padding: vw3(15) vw3(20);
        min-height: 0;
      }
      +.content__award{
        border-top: 1px solid rgba(226, 220, 200, 0.3);
      }
      &-img{
        width: vw2(85);
        height: vw2(85);
        display: flex;
        align-items: center;
        flex-basis: vw2(85);
        flex-shrink: 0;
        margin-right: vw2(73);
        @include mobile{
          width: vw3(50);
          height: vw3(50);
          flex-basis: vw3(50);
          margin-right: vw3(35);
        }
        img{
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          display: block;
        }
      }
      &-title{
        width: vw2(310);
        margin-right: vw2(58);
        @include mobile{
          width: calc(100% - 23vw);
          margin-right: 0;
        }
        p{
          font-size: vw2(22);
          line-height: 130%;
          text-transform: uppercase;
          @include mobile{
            font-size: vw3(17);
          }
        }
        span{
          opacity: 0.5;
        }
      }
      &-text{
        width: vw2(390);
        @include mobile{
          width: 100%;
          box-sizing: border-box;
          margin-top: vw3(15);
          padding-left: vw3(83);
        }
      }
    }

    &__contacts{
      display: flex;
      align-items: center;
      @include mobile{
        display: block;
        margin-top: vw3(40);
      }
    }

    &__brief{
      width: vw2(321);
      margin-right: vw2(193);
      position: relative;
      @include mobile{
        width: 100%;
        margin-right: 0;
      }
      &-title{
        font-size: vw2(18);
        line-height: vw2(35);
        text-decoration-line: underline;
        text-transform: uppercase;
        color: inherit;
        @include mobile{
          font-size: vw3(18);
          line-height: vw3(35);
        }
      }
      &-text{
        margin-top: vw2(24);
        @include mobile{
          margin-top: vw3(20);
        }
        label,span,a{
          color: $orange-color;
        }
      }
    }

    &__form{
      width: vw2(495);
      position: relative;
      @include mobile{
        width: 100%;
        margin-top: vw2(60);
      }
      &-placeholder{
        height: 100%;
        width: 100%;
        font-size: vw2(18);
        line-height: vw2(50);
        opacity: 0.5;
        text-transform: uppercase;
        pointer-events: none;
        user-select: none;
        display: block;
        transition: 0.2s;
        position: absolute;
        top: 0;
        left: 0;
        @include mobile{
          font-size: vw3(18);
          line-height: vw3(50);
        }
      }
      &-input{
        height: vw2(50);
        box-sizing: border-box;
        position: relative;
        display: block;
        width: 100%;
        @include mobile{
          height: vw3(50);
        }
        +.content__form-input{
          margin-top: vw2(56);
          @include mobile{
            margin-top: vw3(20);
          }
        }
        input{
          background: transparent;
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          padding: 0;
          margin: 0;
          outline: none;
          color: inherit;
          border: none;
          border-bottom: 1px solid #E2DCC8;
          font-size: vw2(18);
          line-height: vw2(35);
          @include mobile{
            font-size: vw3(18);
            line-height: vw3(35);
          }
          &.valid,
          &:focus{
            outline: none;
            +.content__form-placeholder{
              font-size: vw2(13);
              line-height: vw2(13);
              top: vw2(-6);
              @include mobile{
                font-size: vw3(13);
                line-height: vw3(13);
                top: vw3(-6);
              }
            }
          }
        }
      }
      &-btn{
        margin-top: vw2(78);
        @include mobile{
          margin-top: vw3(30);
        }
      }
    }

    &__slider{
      margin-left: vw2(339);
      padding-left: vw2(200);
      position: relative;
      box-sizing: border-box;
      margin-top: vw(224);
      @include mobile{
        margin-left: 0;
        padding-left: vw3(50);
        margin-top: vw3(60);
      }
      &::before{
        content: "";
        display: block;
        width: vw2(101);
        height: vw2(87);
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        background: url("../img/q.png") left top no-repeat;
        background-size: contain;
        @include mobile{
          width: vw3(45);
          height: vw3(30);
        }
      }

      &-name{
        margin-top: vw(49);
        font-size: vw2(22);
        line-height: 1.59;
        text-transform: uppercase;
        @include mobile{
          margin-top: vw3(30);
          font-size: vw3(18);
        }
      }
      &-staff{
        margin-top: vw2(8);
        @include mobile{
          margin-top: vw3(8);
        }
      }
    }
  }
}

.slides{
  position: relative;
  width: vw2(580);
  display: flex;
  flex-direction: column;
  @include mobile{
    width: 100%;
  }
  &__container {
    position: relative;
    overflow: hidden;
    display: flex;
    height: vw(340);
    @include mobile{
      height: vw3(250);
    }
  }
  &__inner {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__controls {
    display: flex;
    margin-top: vw2(30);
    @include mobile{
      margin-top: vw3(20);
    }
  }
  &__btn{
    &--prev{
      transform: scaleX(-1);
    }
    &--next{}
     +.slides__btn{
      margin-left: vw2(35);
       @include mobile{
         margin-left: vw3(20);
       }
    }
  }

  .slide {
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
  }


}

.home{
  &__circles{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include mobile{
      position: relative;
      height: vw3(300);
    }
    &-lines{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      div{
        width: 1px;
        height: 0;
        background: rgba(230, 227, 217, 0.3);
        opacity: 0;
        &:first-child,
        &:last-child{
          opacity: 0;
        }
      }
    }
  }

  &__circle{
    width: vw2(608);
    height: vw2(609);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    z-index: 2;
    background: rgba(19, 19, 19, 0.4);
    @include mobile{
      width: vw3(280);
      height: vw3(280);
    }
    &-img{
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
    &-text{
      font-size: vw2(22);
      line-height: 130%;
      @include mobile{
        font-size: vw3(18);
      }
      a{
        text-decoration: underline;
        color: inherit;
        &:hover{
          color: inherit;
          text-decoration: none;
        }
      }
    }

    &--left{
      left: 250%;
      transform: translateY(-50%) translateX(-95%);
      @include mobile{
        left: 0;
        transform: translateY(-50%);
      }
    }
    &--right{
      z-index: 3;
      right: 250%;
      transform: translateY(-50%) translateX(95%);
      @include mobile{
        right: 0;
        transform: translateY(-50%);
      }
    }
  }


  &__numbers{
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    @include mobile{
      flex-wrap: wrap;
      margin-top: vw2(30);
    }
  }
  &__number{
    display: flex;
    align-items: center;
    position: relative;
    @include mobile{
      width: 50%;
    }
    &-digital{
      font-size: vw2(74);
      line-height: 140%;
      @include mobile{
        font-size: vw3(50);
      }
    }
    &-text{
      margin-left: vw2(26);
      @include mobile{
        margin-left: vw3(20);
      }
    }
  }
}

.projects{
  &__menu{}
  &__blocks{
    height: 100%;
    overflow: hidden;
    position: relative;
    @include mobile{
      display: none;
    }
  }
  &__block{
    height: auto;
    overflow: visible;
    display: none;
    +.projects__block{
      padding-top: vw2(50);
    }
    img{
      width: 100%;
      display: block;
      transition: transform 0.3s;
    }
    a{
      display: block;
      width: 100%;
      overflow: hidden;
      img{
        &:hover{
          transform: scale(1.1);
        }
      }
    }
    &.active{
      display: block;
    }
  }
}

.awards{}

.testimonials{
  &__clients{
    margin-top: auto;
    display: flex;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    @include mobile{
      margin-top: vw3(50);
    }
  }
  &__client{
    width: 50%;
    box-sizing: border-box;
    height: vw(120);
    display: flex;
    align-items: center;
    margin: vw2(5) 0;
    padding: 0 vw2(5);
    position: relative;
    @include mobile{
      height: vw3(80);
      margin: vw3(5) 0;
      padding: 0 vw3(5);
    }
    img{
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.news{
  &__menu{}
  &__blocks{
    height: 100%;
    overflow: hidden;
    padding-right: vw2(30);
    @include mobile{
      display: none;
    }
  }
  &__block{
    height: auto;
    overflow: visible;
    display: none;
    +.news__block {
      padding-top: vw2(100);
    }
    img{
      width: 100%;
      display: block;
      transition: transform 0.3s;
    }
    a{
      display: block;
      width: 100%;
      overflow: hidden;
      img{
        &:hover{
          transform: scale(1.1);
        }
      }
    }
    &-info{
      display: flex;
      justify-content: space-between;
      margin-bottom: vw2(55);
    }
    &-date{
      width: vw2(380);
      opacity: 0.6;
    }
    &-text{
      width: vw2(390);
    }
    &-number{
      font-weight: 500;
      font-size: vw2(40);
      line-height: 1;
    }


    &.active{
      display: block;
    }
  }
}

.contacts{
  &__copyright{
    margin-top: auto;
    position: relative;
    @include mobile{
      margin-top: vw3(30);
    }
  }
  &__form{
    margin-top: vw(80);
    @include mobile{
      margin-top: vw3(50);
    }
  }
}